@import './common/style/mixin';
.App {
  padding: 10px 0;
  text-align: center;
}
.flag {
  position: relative;
  width: rem(750);
  height: rem(500);
  background-color: #ff2121;
}
.icon {
  position: absolute;
  left: rem(50);
  top: rem(80);
}
.icon1 {
  position: absolute;
  top: rem(50);
  left: rem(180);
  transform: rotate(-15deg);
}
.icon2 {
  position: absolute;
  top: rem(130);
  left: rem(200);
  transform: rotate(-20deg);
}
.icon3 {
  position: absolute;
  top: rem(210);
  left: rem(190);
}
.icon4 {
  position: absolute;
  top: rem(270);
  left: rem(150);
  transform: rotate(15deg);
}
