@charset "UTF-8";
$rem: 75 !default;
$min_max: false !default;
$fontRem: true !default;
$mobile: true !default;
$retina: false !default;
$borderLineRetina: true !default;
$arrows-color: #515151 !default;
$arrows-bg: #fff !default;
$arrows-size: 8px !default;
$arrows-right: 8px !default;
$arrows-top: 8px !default;
$imagesPath: "../../../images/common" !default;
@mixin arrows-r($top:$arrows-top, $right:$arrows-right, $color:$arrows-color, $size:$arrows-size, $bg:$arrows-bg) {
    &:before,
    &:after {
        content: "";
        position: absolute;
        right: $right;
        top: $top;
        width: 0;
        height: 0;
        @include solid($size, transparent, all, false);
        border-left-color: $color;
    }
    &:after {
        right: $right + 1px;
        border-left-color: $bg;
    }
    @if $retina {
        [data-dpr="2"] & {
            &:before {
                right: $right * 2;
                border-left-color: $color;
            }
            &:after {
                right: $right * 2 + 2px;
                border-left-color: $bg;
            }
        }
        [data-dpr="3"] & {
            &:before {
                right: $right * 3;
                border-left-color: $color;
            }
            &:after {
                right: $right * 3 + 3px;
                border-left-color: $bg;
            }
        }
    }
}

@mixin arrows-u($top:$arrows-top, $right:$arrows-right, $color:$arrows-color, $size:$arrows-size, $bg:$arrows-bg) {
    &:before,
    &:after {
        content: "";
        position: absolute;
        right: $right;
        top: $top;
        width: 0;
        height: 0;
        @include solid($size, transparent, all, false);
        border-bottom-color: $color;
    }
    &:after {
        top: $top + 1px;
        border-bottom-color: $bg;
    }
    @if $retina {
        [data-dpr="2"] & {
            &:before {
                top: $top * 2;
                border-bottom-color: $color;
            }
            &:after {
                top: $top * 2 + 2px;
                border-bottom-color: $bg;
            }
        }
        [data-dpr="3"] & {
            &:before {
                top: $top * 3;
                border-bottom-color: $color;
            }
            &:after {
                top: $top * 3 + 3px;
                border-bottom-color: $bg;
            }
        }
    }
}

@mixin arrows-d($top:$arrows-top, $right:$arrows-right, $color:$arrows-color, $size:$arrows-size, $bg:$arrows-bg) {
    &:before,
    &:after {
        content: "";
        position: absolute;
        right: $right;
        top: $top;
        width: 0;
        height: 0;
        @include solid($size, transparent, all, false);
        border-top-color: $color;
    }
    &:after {
        top: $top - 1px;
        border-top-color: $bg;
    }
    @if $retina {
        [data-dpr="2"] & {
            &:before {
                top: $top * 2;
                border-top-color: $color;
            }
            &:after {
                top: $top * 2 - 2px;
                border-top-color: $bg;
            }
        }
        [data-dpr="3"] & {
            &:before {
                top: $top * 3;
                border-top-color: $color;
            }
            &:after {
                top: $top * 3 - 3px;
                border-top-color: $bg;
            }
        }
    }
}

@mixin arrows-l($top:$arrows-top, $right:$arrows-right, $color:$arrows-color, $size:$arrows-size, $bg:$arrows-bg) {
    &:before,
    &:after {
        content: "";
        position: absolute;
        right: $right;
        top: $top;
        width: 0;
        height: 0;
        @include solid($size, transparent, all, false);
        border-right-color: $color;
    }
    &:after {
        right: $right - 1px;
        border-right-color: $bg;
    }
    @if $retina {
        [data-dpr="2"] & {
            &:before {
                right: $top * 2;
                border-right-color: $color;
            }
            &:after {
                right: $top * 2 - 2px;
                border-right-color: $bg;
            }
        }
        [data-dpr="3"] & {
            &:before {
                right: $top * 3;
                border-right-color: $color;
            }
            &:after {
                right: $top * 3 - 3px;
                border-right-color: $bg;
            }
        }
    }
}

@mixin arrow-right($wh: 20px, $border: 5px) {
    position: relative;
    @include wh($wh);
    &:before,
    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: $border solid transparent;
        border-right: $border solid transparent;
        border-bottom: $border solid transparent;
        border-left-width: $border;
        border-left-style: solid;
    }
    &:after {
        right: 1px;
    }
}

@mixin arrow-down($wh: 20px, $border: 5px) {
    position: relative;
    @include wh($wh, $wh / 2);
    &:before,
    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-left: $border solid transparent;
        border-right: $border solid transparent;
        border-bottom: $border solid transparent;
        border-top-width: $border;
        border-top-style: solid;
    }
    &:after {
        top: -1px;
    }
}

@mixin plus($wh: 20px, $border: 1px, $direction: center, $top: 0, $left: 0) {
    position: relative;
    &:before,
    &:after {
        content: "";
        @if $direction=center {
            @include center;
        }
        @else if $direction=top_center {
            @include top_center;
        }
        @else if $direction=left_center {
            @include left_center;
        }
        @else {
            position: absolute;
            top: $top;
            left: $left;
        }
    }
    &:before,
    &:after {
        @include wh($wh, 0);
        border-bottom-width: $border;
        border-bottom-style: solid;
    }
    &:after {
        @include wh(0, $wh);
        border-right-width: $border;
        border-right-style: solid;
    }
}

@mixin minus($wh: 20px, $border: 1px, $direction: center, $top: 0, $left: 0) {
    position: relative;
    &:before {
        content: "";
        @if $direction=center {
            @include center;
        }
        @else if $direction=top_center {
            @include top_center;
        }
        @else if $direction=left_center {
            @include left_center;
        }
        @else {
            position: absolute;
            top: $top;
            left: $left;
        }
    }
    &:before,
    &:after {
        @include wh($wh, 0);
        border-bottom-width: $border;
        border-bottom-style: solid;
    }
}

//去掉一个值的单位，如12px => 12
// eg. strip-units(12px) => 12
@function strip-units($number) {
    @return $number / ($number * 0 + 1);
}

//获取数组数据
@function getArray($value, $index) {
    $len: length($value);
    @if $index>$len {
        $index: $len;
    }
    @else if $index <=0 {
        $index: 1;
    }
    @else if $len==0 {
        @return 0;
    }
    @return nth($value, $index);
}

//px转rem
@function rem($px, $isArr:false, $index:1, $base:$rem) {
    @if $isArr {
        $v: getArray($px, $index);
        @if $v==auto or $v==0 or unit($v)=="%" {
            @return $v;
        }
        @else {
            @if $mobile {
                @return (strip-units(getArray($px, $index)) / $base) * 1rem;
            }
            @else {
                @return getArray($px, $index);
            }
        }
    }
    @else if type_of($px)=="list" {
        $r: "";
        $count: 0;
        $len: length($px);
        @each $v in $px {
            $count: $count+1;
            @if $v==auto or $v==0 or unit($v)=="%" {
                $r: $r+$v;
            }
            @else {
                @if $mobile {
                    $r: $r+(strip-units($v) / $base) * 1rem;
                }
                @else {
                    $r: $r+$v;
                }
            }
            @if $count < $len {
                $r: $r+" ";
            }
        }
        @return unquote($r);
    }
    @else {
        @if $px==auto or $px==0 or unit($px)=="%" {
            @return $px;
        }
        @else {
            @if $mobile {
                @return (strip-units($px) / $base) * 1rem;
            }
            @else {
                @return $px;
            }
        }
    }
}

@function new_value($px, $ratio) {
    @if type_of($px)=="list" {
        $r: "";
        $count: 0;
        $len: length($px);
        @each $v in $px {
            $count: $count+1;
            @if $v==auto or $v==0 or unit($v)=="%" {
                $r: $r+$v;
            }
            @else {
                $r: $r+$v * $ratio;
            }
            @if $count < $len {
                $r: $r+" ";
            }
        }
        @return unquote($r);
    }
    @else {
        @if $px==auto or $px==0 or unit($px)=="%" {
            @return $px;
        }
        @else {
            @return $px * $ratio;
        }
    }
}

@function pxToRem($px, $base:$rem) {
    @return rem($px, false, 1, $rem);
}

@function r($px, $base:$rem) {
    @return rem($px, false, 1, $rem);
}

@function img-url($name, $path: $imagesPath) {
    $path: unquote($path);
    $name: unquote($name);
    $len: str-length($name);
    $jpg: str-slice($name, $len - 4, $len);
    $end: str-slice($name, $len - 3, $len);
    @if $jpg==".jpeg" or $end==".png" or $end==".gif" or $end==".jpg" {
        @return url("#{$path}/#{$name}");
    }
    @else {
        @return url("#{$path}/#{$name}.png");
    }
}

@mixin setValue($property, $v) {
    #{$property}: $v;
    @if $retina {
        [data-dpr="2"] & {
            #{$property}: $v * 2;
        }
        [data-dpr="3"] & {
            #{$property}: $v * 3;
        }
    }
}

@mixin wh($size...) {
    @if $min_max {
        min-width: rem($size, true, 1);
        max-width: rem($size, true, 1);
    }
    width: rem($size, true, 1);
    @if $min_max {
        min-height: rem($size, true, 2);
        max-height: rem($size, true, 2);
    }
    height: rem($size, true, 2);
}

@mixin width($size) {
    @if $min_max {
        min-width: rem($size);
        max-width: rem($size);
    }
    width: rem($size);
}

@mixin min-width($size) {
    min-width: rem($size);
}

@mixin max-width($size) {
    max-width: rem($size);
}

@mixin height($size) {
    @if $min_max {
        min-height: rem($size);
        max-height: rem($size);
    }
    height: rem($size);
}

@mixin min-height($size) {
    min-height: rem($size);
}

@mixin max-height($size) {
    max-height: rem($size);
}

@mixin min-size($size...) {
    min-width: rem($size, true, 1);
    min-height: rem($size, true, 2);
}

// clearfix
// 闭合子元素的浮动
@mixin clearfix($extend:true) {
    @if $extend {
        @extend %clearfix;
    }
    @else {
        &:before,
        &:after {
            content: "";
            display: table;
        }
        &:after {
            clear: both;
        }
    }
}

%clearfix {
    @include clearfix(false);
}

// Hide from both screenreaders and browsers: h5bp.com/u
// 既隐藏于视觉也隐藏于屏幕浏览器
@mixin hidden($extend:true) {
    @if $extend {
        @extend %hidden;
    }
    @else {
        display: none !important;
        visibility: hidden;
    }
}

%hidden {
    @include hidden(false);
}

// Hide only visually, but have it available for screenreaders
// 只隐藏于视觉，屏幕浏览器可以阅读
@mixin visually-hidden($extend:true) {
    @if $extend {
        @extend %visually-hidden;
    }
    @else {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px); //standard
    }
}

%visually-hidden {
    @include visually-hidden(false);
}

// ul has list style
@mixin ul-has-style($style:disc) {
    margin-left: 25px;
    list-style: disc;
}

%ul-has-style {
    @include ul-has-style;
}

// 图片替换文字
// 高级浏览器直接2就可以
// 原生7,8不支持color的tr ansparent，所以采用1;
// ie6用1会有一条细线，所以采用3
// 如果要兼容ie6，使用的时候请确定元素设置高度。
@mixin ir() {
    font: 0/0 a; //1
    text-shadow: none;
    border: 0 none;
    color: transparent; //2
}

%ir {
    @include ir;
}

// Text overflow
// 元素可以设置宽度才可应用省略号
%ellipsis-basic {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin ellipsis($width:100%) {
    @extend %ellipsis-basic;
    width: $width;
}

@mixin line-clamp($line:1) {//多行省略号
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

// triangle
%triangle-basic {
    content: "";
    height: 0;
    width: 0;
    overflow: hidden;
}

@mixin triangle($direction, $size, $borderColor) {
    @extend %triangle-basic;
    @if $direction==top {
        border-bottom: $size solid $borderColor;
        border-left: $size dashed transparent;
        border-right: $size dashed transparent;
    }
    @else if $direction==right {
        border-left: $size solid $borderColor;
        border-top: $size dashed transparent;
        border-bottom: $size dashed transparent;
    }
    @else if $direction==bottom {
        border-top: $size solid $borderColor;
        border-left: $size dashed transparent;
        border-right: $size dashed transparent;
    }
    @else if $direction==left {
        border-right: $size solid $borderColor;
        border-top: $size dashed transparent;
        border-bottom: $size dashed transparent;
    }
}

// disabled
// 禁用样式，加!important
@mixin disabled($bgColor:#e6e6e6, $textColor:#ababab) {
    background-color: $bgColor !important;
    color: $textColor !important;
    cursor: not-allowed !important;
}

%disabled {
    @include disabled;
}

// 水平间隔线，适用于行列表
@mixin horizontal-line($border:1px dashed #ccc, $padding:10px) {
    border-bottom: $border;
    padding-top: $padding;
    padding-bottom: $padding;
}

%horizontal-line {
    @include horizontal-line;
}

// Retina images.
// @include image-2x("../images/img-2x.png", 200px, 200px);
// Use with care - http://37signals.com/svn/posts/3271-easy-retina-ready-images-using-scss
@mixin image-2x($url, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        // on retina, use image that's scaled by 2
        background-image: url('#{$url}');
        background-size: $width $height;
    }
}

// 针对火狐浏览器打bug
// @include firefox{.test{padding-bottom:3px;}}
@mixin firefox {
    @-moz-document url-prefix() {
        @content;
    }
} // px转em
@function pxToEm($px, $base: 16) {
    @return ($px / $base) * 1em;
}

// 为颜色添加白色，以百分比形式
@function tint($color, $percent) {
    @return mix(white, $color, $percent);
}

// 为颜色添加黑色，以百分比形式
@function shade($color, $percent) {
    @return mix(black, $color, $percent);
}

//css3
// 各个浏览器的属性前缀，true表示开启，false表示不开启
//-----------------------------------------------------
$prefixForWebkit: true !default;
$prefixForMozilla: false !default;
$prefixForMicrosoft: false !default;
$prefixForOpera: false !default; //opera从版本15开始转向webkit，所以默认为false，不输出o前缀
$prefixNo: true !default;
// 用于flex的兼容变量
$flexOld: false !default; //09版本 用于兼容移动端，-webkit前缀
$flexMid: true !default; //用于ie10 -ms前缀
// prefixer
// 用于在属性上加前缀
// 默认这里将只输出webkit前缀和标准（如果要开启opera的可以将$prefixForOpera设置为true）
//-----------------------------------------------------
@mixin prefixer($property, $value, $prefixes: o webkit) {
    @each $prefix in $prefixes {
        @if $prefix==webkit and $prefixForWebkit==true {
            -webkit-#{$property}: $value;
        }
        @else if $prefix==moz and $prefixForMozilla==true {
            -moz-#{$property}: $value;
        }
        @else if $prefix==ms and $prefixForMicrosoft==true {
            -ms-#{$property}: $value;
        }
        @else if $prefix==o and $prefixForOpera==true {
            -o-#{$property}: $value;
        }
    }
    @if $prefixNo {
        #{$property}: $value;
    }
}

// prefixer-value
// 用于在值上加前缀
// 默认这里将输出webkit前缀，moz前缀和标准
@mixin prefixer-value($property, $value, $prefixes: webkit moz) {
    @each $prefix in $prefixes {
        @if $prefix==webkit and $prefixForWebkit==true {
            #{$property}: -webkit-#{$value};
        }
        @else if $prefix==moz and $prefixForMozilla==true {
            #{$property}: -moz-#{$value};
        }
        @else if $prefix==o and $prefixForMozilla==true {
            #{$property}: -o-#{$value};
        }
        @else if $prefix==ms and $prefixForMicrosoft==true {
            #{$property}: -ms-#{$value};
        }
    }
    @if $prefixNo {
        #{$property}: $value;
    }
}

// disable prefix
// 设置所有前缀和标准为false，用于@keyframes
@mixin disable-prefix-for-all() {
    $prefixForWebkit: false !global;
    $prefixForMozilla: false !global;
    $prefixForMicrosoft: false !global;
    $prefixForOpera: false !global;
    $prefixNo: false !global;
}

// Return vendor-prefixed property names if appropriate
// 将transition-property中的需要加前缀的属性添加相应的前缀，如transform
// transitionPropertyNames((transform, color, background), moz) -> -moz-transform, color, background
//-----------------------------------------------------
@function transitionPropertyName($prop, $vendor: false) {
    // put other properties that need to be prefixed here aswell
    @if $vendor==webkit and $prefixForWebkit==true and $prop==transform {
        @return unquote('-webkit-'+$prop);
    }
    @if $vendor==moz and $prefixForMozilla==true and $prop==transform {
        @return unquote('-moz-'+$prop);
    }
    @if $vendor==o and $prefixForOpera==true and $prop==transform {
        @return unquote('-o-'+$prop);
    }
    @else {
        @return $prop;
    }
}

@function transitionPropertyNames($props, $vendor: false) {
    $new-props: ();
    @each $prop in $props {
        $new-props: append($new-props, transitionPropertyName($prop, $vendor), comma);
    }
    @return $new-props;
}

// border-image
// http://border-image.com/
@mixin border-image($image) {
    @include prefixer(border-image, $image);
}

// box-sizing
@mixin box-sizing($type: border-box) {
    // border-box | padding-box | content-box
    @include prefixer(box-sizing, $type, webkit moz o);
}

// box-shadow
@mixin box-shadow($shadow...) {
    @if length($shadow)>=1 {
        @include prefixer(box-shadow, $shadow);
    }
    @else {
        $shadow: 0 0 4px rgba(0, 0, 0, .3);
        @include prefixer(box-shadow, $shadow);
    }
}

@mixin opacity($o: 50) {
    opacity: $o / 100;
    filter: alpha(opacity=#{$o});
}

// appearance
@mixin appearance($value) {
    @include prefixer(appearance, $value, webkit moz);
}

// hyphens
@mixin hyphens($value: auto) {
    // none | manual | auto
    @include prefixer(hyphens, $value, webkit moz);
}

// image-rendering
// https://developer.mozilla.org/en-US/docs/Web/CSS/image-rendering
@mixin image-rendering($rendering: crisp-edges) {
    @if $rendering==crisp-edges {
        image-rendering: -moz-crisp-edges;
        /* Firefox */
        image-rendering: -webkit-optimize-contrast;
        /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }
    @else {
        @include prefixer-value(image-rendering,  webkit moz);
    }
}

// User select
// https://developer.mozilla.org/en-US/docs/Web/CSS/user-select
@mixin user-select($arg: text) {
    // none | text | all | element
    @include prefixer(user-select,
    $arg);
}

// Background-image
//-----------------------------------------------------
@mixin background-clip($clip) {
    // border-box | padding-box | content-box
    @if length($clip)>=1 {
        @include prefixer(background-clip, $clip, webkit);
    }
    @else {
        @include prefixer(background-clip, padding-box, webkit);
    }
}

@mixin background-origin($origin...) {
    // border-box | padding-box | content-box
    @if length($origin)>=1 {
        @include prefixer(background-origin, $origin, webkit);
    }
    @else {
        @include prefixer(background-origin, border-box, webkit);
    }
}

@mixin background-size($size...) {
    // <length> | auto | cover | contain
    @if length($size)>=1 {
        @include prefixer(background-size, $size, webkit);
    }
    @else {
        @include prefixer(background-size, cover, webkit);
    }
}

@mixin size($x...) {
    @if length($x)==0 {
        background-size: 100% 100%;
    }
    @else {
        background-size: rem($x, true, 1) rem($x, true, 2);
    }
}

// Gradients
// Firefox 10+, Opera 11.6+, Chrome 26+ and IE10 also support the new "to (side)" syntax.
//-----------------------------------------------------
// 水平渐变，从左往右
// @include gradient-horizontal(#333, #ccc);
@mixin gradient-horizontal($gradient...) {
    background-image: -webkit-linear-gradient(left, $gradient); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(left, $gradient); // Opera 11.10
    background-image: linear-gradient(to right, $gradient); // Standard, IE10
}

// 水平渐变，从左往右，平铺
// @include gradient-horizontal-repeating(#333 5%, #ccc 10%);
@mixin gradient-horizontal-repeating($gradient...) {
    background-image: -webkit-repeating-linear-gradient(left, $gradient); // Safari 5.1+, Chrome 10+
    background-image: -o-repeating-linear-gradient(left, $gradient); // Opera 11.10
    background-image: repeating-linear-gradient(to right, $gradient); // Standard, IE10
}

// 垂直渐变，从上往下
// @include gradient-vertical(#333 30%, #ccc);
@mixin gradient-vertical($gradient...) {
    background-image: -webkit-linear-gradient(top, $gradient); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(top, $gradient); // Opera 11.10
    background-image: linear-gradient(to bottom, $gradient); // Standard, IE10
}

// 垂直渐变，从上往下，平铺
// @include gradient-vertical-repeating(#333 30%, #ccc 50%);
@mixin gradient-vertical-repeating($gradient...) {
    background-image: -webkit-repeating-linear-gradient(top, $gradient); // Safari 5.1+, Chrome 10+
    background-image: -o-repeating-linear-gradient(top, $gradient); // Opera 11.10
    background-image: repeating-linear-gradient(to bottom, $gradient); // Standard, IE10
}

// 角度渐变
// @include gradient-angle(45deg, #333 30%, #ccc);
@mixin gradient-angle($gradient...) {
    background-image: -webkit-linear-gradient($gradient); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient($gradient); // Opera 11.10
    background-image: linear-gradient($gradient); // Standard, IE1
}

// 角度渐变
// @include gradient-angle(45deg, #333 30%, #ccc 50%);
@mixin gradient-angle-repeating($gradient...) {
    background-image: -webkit-repeating-linear-gradient($gradient); // Safari 5.1+, Chrome 10+
    background-image: -o-repeating-linear-gradient($gradient); // Opera 11.10
    background-image: repeating-linear-gradient($gradient); // Standard, IE1
}

// 径向渐变，可以写点简单的
// 如：@include gradient-radial(#00F,#FFF);
// 而@include gradient-radial(farthest-side circle at right,#00F,#FFF);这种的将不会兼容，所以不要调用这个，可以去找工具生成
@mixin gradient-radial($gradient...) {
    background-image: -webkit-radial-gradient($gradient); // Safari 5.1+, Chrome 10+
    background-image: -o-radial-gradient($gradient); // Opera 11.10
    background-image: radial-gradient($gradient); // Standard, IE1
    background-repeat: no-repeat;
}

// Transform
//-----------------------------------------------------
@mixin transform($property...) {
    @include prefixer(transform, $property);
}

@mixin transform-origin($axes) {
    // x-axis - left | center | right  | length | %
    // y-axis - top  | center | bottom | length | %
    // z-axis -                          length
    @include prefixer(transform-origin, $axes);
}

@mixin transform-style($style: preserve-3d) {
    // flat | preserve-3d
    @include prefixer(transform-style, $style);
}

// Transformations
//------------------------------------
// rotate
@mixin rotate($degrees...) {
    @include prefixer(transform, rotate($degrees));
}

@mixin rotate3D($degrees...) {
    @include prefixer(transform, rotate3D($degrees));
}

@mixin rotateX($degrees) {
    @include prefixer(transform, rotateX($degrees));
}

@mixin rotateY($degrees) {
    @include prefixer(transform, rotateY($degrees));
}

@mixin rotateZ($degrees) {
    @include prefixer(transform, rotateZ($degrees));
}

// scale
@mixin scale($ratio...) {
    @include prefixer(transform, scale($ratio));
}

@mixin scale3D($ratio...) {
    @include prefixer(transform, scale3D($ratio));
}

@mixin scaleX($ratio) {
    @include prefixer(transform, scaleX($ratio));
}

@mixin scaleY($ratio) {
    @include prefixer(transform, scaleY($ratio));
}

@mixin scaleZ($ratio) {
    @include prefixer(transform, scaleZ($ratio));
}

// translate
@mixin translate($px...) {
    @include prefixer(transform, translate($px));
}

@mixin translate3D($px...) {
    @include prefixer(transform, translate3D($px));
}

@mixin translateX($px) {
    @include prefixer(transform, translateX($px));
}

@mixin translateY($px) {
    @include prefixer(transform, translateY($px));
}

@mixin translateZ($px) {
    @include prefixer(transform, translateZ($px));
}

@mixin backface-visibility($visibility:hidden) {
    // visible | hidden
    @include prefixer(backface-visibility, $visibility);
}

// skew
// skew时一般会有锯齿什么的，解决方法是添加backface-visibility为hidden
// 详见 https://github.com/twitter/bootstrap/issues/5319
@mixin skew($degrees...) {
    @include prefixer(transform, skew($degrees));
    @include backface-visibility;
}

@mixin skewX($degrees) {
    @include prefixer(transform, skewX($degrees));
    @include backface-visibility;
}

@mixin skewY($degrees) {
    @include prefixer(transform, skewY($degrees));
    @include backface-visibility;
}

// matrix
@mixin matrix($args...) {
    @include prefixer(transform, matrix($args));
}

@mixin matrix3D($args...) {
    @include prefixer(transform, matrix3D($args));
}

// perspective
//-----------------------------------------------------
@mixin perspective($depth: none) {
    // none | <length>
    @include prefixer(perspective, $depth);
}

@mixin perspective-origin($value: 50% 50%) {
    // x-axis - left | center | right  | length | %
    // y-axis - top  | center | bottom | length | %
    @include prefixer(perspective-origin, $value);
}

@mixin backface-visibility($visibility:hidden) {
    // visible | hidden
    @include prefixer(backface-visibility, $visibility);
}

// transition
//-----------------------------------------------------
@mixin transition($properties...) {
    @if length($properties)>=1 {
        @include prefixer(transition, $properties);
    }
    @else {
        $properties: all 0.3s ease;
        @include prefixer(transition, $properties);
    }
}

// 默认不提供moz的，如有需要请自行添加
// -moz-transition-property: transitionPropertyNames($properties, moz);
@mixin transition-property($properties...) {
    -webkit-transition-property: transitionPropertyNames($properties, webkit);
    @if $prefixForOpera {
        -o-transition-property: transitionPropertyNames($properties, o);
    }
    transition-property: transitionPropertyNames($properties,
    false);
}

@mixin transition-duration($times...) {
    @include prefixer(transition-duration, $times);
}

@mixin transition-timing-function($motions...) {
    // timing-function
    // https://developer.mozilla.org/en-US/docs/Web/CSS/timing-function
    @include prefixer(transition-timing-function, $motions);
}
@mixin transition-delay($times...) {
    @include prefixer(transition-delay, $times);
}

// animation
//-----------------------------------------------------
@mixin animation($animations...) {
    @include prefixer(animation, $animations);
}

@mixin animation-name($names...) {
    @include prefixer(animation-name, $names);
}

@mixin animation-duration($times...) {
    @include prefixer(animation-duration, $times);
}

@mixin animation-timing-function($motions...) {
    // timing-function
    // https://developer.mozilla.org/en-US/docs/Web/CSS/timing-function
    @include prefixer(animation-timing-function, $motions);
}
@mixin animation-delay($times...) {
    @include prefixer(animation-delay, $times);
}

@mixin animation-iteration-count($values...) {
    // infinite | <number>
    @if length($values)>=1 {
        @include prefixer(animation-iteration-count, $values);
    }
    @else {
        @include prefixer(animation-iteration-count, infinite);
    }
}
@mixin animation-direction($directions...) {
    // normal | alternate | reverse | alternate-reverse
    @include prefixer(animation-direction, $directions);
}

@mixin animation-fill-mode($modes...) {
    // none | forwards | backwards | both
    @if length($modes)>=1 {
        @include prefixer(animation-fill-mode, $modes);
    }
    @else {
        @include prefixer(animation-fill-mode, forwards);
    }
}
@mixin animation-play-state($states...) {
    // running | paused
    @if length($states)>=1 {
        @include prefixer(animation-play-state, $states);
    }
    @else {
        @include prefixer(animation-play-state, paused);
    }
}

// 在各自的@if判断里面，先禁用所有的前缀，然后开启对应的前缀
// 最后输出标准的时候，直接禁用所有的前缀，开启标准
@mixin keyframes($name, $prefixes: webkit o) {
    $originalPrefixForWebkit: $prefixForWebkit;
    $originalPrefixForMozilla: $prefixForMozilla;
    $originalPrefixForMicrosoft: $prefixForMicrosoft;
    $originalPrefixForOpera: $prefixForOpera;
    $originalPrefixNo: $prefixNo;
    @each $prefix in $prefixes {
        @if $prefix==webkit and $originalPrefixForWebkit==true {
            @include disable-prefix-for-all();
            $prefixForWebkit: true !global;
            @-webkit-keyframes #{$name} {
                @content;
            }
        }
        @if $prefix==moz and $originalPrefixForMozilla==true {
            @include disable-prefix-for-all();
            $prefixForMozilla: true !global;
            @-moz-keyframes #{$name} {
                @content;
            }
        }
        @if $prefix==ms and $originalPrefixForMicrosoft==true {
            @include disable-prefix-for-all();
            $prefixForMicrosoft: true !global;
            @-ms-keyframes #{$name} {
                @content;
            }
        }
        @if $prefix==o and $originalPrefixForOpera==true {
            @include disable-prefix-for-all();
            $prefixForOpera: true !global;
            @-o-keyframes #{$name} {
                @content;
            }
        }
    }
    @include disable-prefix-for-all();
    $prefixNo: true !global;
    @keyframes #{$name} {
        @content;
    }
    $prefixForWebkit: $originalPrefixForWebkit !global;
    $prefixForMozilla: $originalPrefixForMozilla !global;
    $prefixForMicrosoft: $originalPrefixForMicrosoft !global;
    $prefixForOpera: $originalPrefixForOpera !global;
    $prefixNo: $originalPrefixNo !global;
}

// 为animate模块设计
%animated {
    @include animation-duration(1s);
    @include animation-fill-mode(both);
}

//flex
//-----------------------------------------------------
// flex-old和flex-mid分别应用与old版本和mid版本
//----------------------------
@mixin flex-old($property, $value, $propertyPrefix:true) {
    @if $flexOld {
        @if $propertyPrefix {
            -webkit-#{$property}: $value;
        }
        @else {
            #{$property}: -webkit-#{$value};
        }
    }
}

@mixin flex-mid($property, $value, $propertyPrefix:true) {
    @if $flexMid {
        @if $propertyPrefix {
            -ms-#{$property}: $value;
        }
        @else {
            #{$property}: -ms-#{$value};
        }
    }
}

@mixin display-flex($flex: flex) {
    // flex | inline-flex
    @if $flex==flex {
        @include flex-old(display, box, false);
        @include flex-mid(display, flexbox, false);
    }
    @if $flex==inline-flex {
        @include flex-old(display, inline-box, false);
        @include flex-mid(display, inline-flexbox, false);
    }
    @include prefixer-value(display, $flex);
}

@mixin flex-direction($direction: column) {
    // row | row-reverse | column | column-reverse
    @if $direction==row {
        @include flex-old(box-orient, horizontal);
    }
    @if $direction==column {
        @include flex-old(box-orient, vertical);
    }
    @if $direction==row-reverse {
        @include flex-old(box-orient, horizontal);
        @include flex-old(box-direction, reverse);
    }
    @if $direction==column-reverse {
        @include flex-old(box-orient, vertical);
        @include flex-old(box-direction, reverse);
    }
    @include flex-mid(flex-direction, $direction);
    @include prefixer(flex-direction, $direction, webkit moz);
}

@mixin flex-wrap($wrap: wrap) {
    // nowrap | wrap | wrap-reverse
    // old版本不支持wrap-reverse
    @if $wrap==wrap {
        @include flex-old(box-lines, multiple);
    }
    @if $wrap==nowrap {
        @include flex-old(box-lines, single);
    }
    @include flex-mid(flex-wrap, $wrap);
    @include prefixer(flex-wrap, $wrap, webkit moz);
}

// flex-flow is a shorthand for flex-direction and flex-wrap
@mixin flex-flow($flow: row wrap) {
    // direction || wrap
    @include prefixer(flex-flow, $flow, webkit moz);
}

@mixin order($num: -1) {
    @include flex-old(box-ordinal-group, $num);
    @include flex-mid(flex-order, $num);
    @include prefixer(order, $num, webkit moz);
}

@mixin justify-content($align: space-between) {
    // flex-start | flex-end | center | space-between | space-around
    @if $align==flex-start {
        @include flex-old(box-pack, start);
        @include flex-mid(flex-pack, start);
    }
    @else if $align==flex-end {
        @include flex-old(box-pack, end);
        @include flex-mid(flex-pack, end);
    }
    @else if $align==space-between {
        @include flex-old(box-pack, justify);
        @include flex-mid(flex-pack, justify);
    }
    @else if $align==space-around {
        @include flex-mid(flex-pack, distribute);
    }
    @else {
        @include flex-old(box-pack, $align);
        @include flex-mid(flex-pack, $align);
    }
    @include prefixer(justify-content, $align, webkit moz);
}
@mixin align-items($align: center) {
    // flex-start | flex-end | center | baseline | stretch
    @if $align==flex-start {
        @include flex-old(box-align, start);
        @include flex-mid(flex-align, start);
    }
    @else if $align==flex-end {
        @include flex-old(box-align, end);
        @include flex-mid(flex-align, end);
    }
    @else {
        @include flex-old(box-align, $align);
        @include flex-mid(flex-align, $align);
    }
    @include prefixer(align-items, $align, webkit moz);
}

@mixin align-content($align: flex-start) {
    // stretch | flex-start | flex-end | center | space-between | space-around
    // old不支持
    @if $align==flex-start {
        @include flex-mid(flex-line-pack, start);
    }
    @else if $align==flex-end {
        @include flex-mid(flex-line-pack, end);
    }
    @else if $align==space-between {
        @include flex-mid(flex-line-pack, justify);
    }
    @else if $align==space-around {
        @include flex-mid(flex-line-pack, distribute);
    }
    @else {
        @include flex-mid(flex-line-pack, $align);
    }
    @include prefixer(align-content, $align, webkit moz);
}
@mixin align-self($align: flex-start) {
    // auto | flex-start | flex-end | center | baseline | stretch
    // old不支持
    @if $align==flex-start {
        @include flex-mid(flex-item-align, start);
    }
    @else if $align==flex-end {
        @include flex-mid(flex-item-align, end);
    }
    @else {
        @include flex-mid(flex-item-align, $align);
    }
    @include prefixer(align-self, $align, webkit moz);
}

// https://developer.mozilla.org/en-US/docs/Web/CSS/flex
@mixin flex($arg) {
    //initial | auto | none | number
    // 只传入数字则三大版本都支持
    @if type-of($arg)==number {
        @include flex-old(box-flex, $arg);
        @include flex-mid(flex, $arg);
    }
    @include prefixer(flex, $arg, webkit moz);
}

// mask
// http://www.w3.org/TR/css-masking-1/
// http://ued.ctrip.com/blog/wp-content/webkitcss/index.html
// https://www.webkit.org/blog/181/css-masks/
// mask (background)
// mask-image (background-image)
// mask-position (background-position)
// mask-size (background-size)
// mask-repeat (background-repeat)
// mask-clip (background-clip)
// mask-origin (background-origin)
// mask-box-image (border-image)
//-----------------------------------------------------
@mixin mask($mask...) {
    @include prefixer(mask, $mask, webkit moz);
}

@mixin mask-image($image...) {
    @include prefixer(mask-image, $image, webkit moz);
}

@mixin mask-position($position...) {
    @include prefixer(mask-position, $position, webkit moz);
}
@mixin mask-repeat($repeat...) {
    @include prefixer(mask-repeat, $repeat, webkit moz);
}

@mixin mask-origin($origin...) {
    @include prefixer(mask-origin, $origin, webkit moz);
}

@mixin mask-clip($clip...) {
    @include prefixer(mask-clip, $clip, webkit moz);
}

@mixin mask-type($type: luminance) {
    @include prefixer(mask-type, $type, webkit moz);
}

@mixin mask-box-image($box) {
    @include prefixer(mask-box-image, $box, webkit moz);
}

// filter
//-----------------------------------------------------
@mixin filter($function...) {
    // <filter-function> [<filter-function>]* | none
    @include prefixer(filter, $function, webkit moz);
}

@mixin filter-blur($px) {
    @include prefixer(filter, blur($px), webkit moz);
}
@mixin filter-grayscale($percent: 100%) {
    @include prefixer(filter, grayscale($percent), webkit moz);
}

// @include url("filepath");
@mixin filter-url($url) {
    @include prefixer(filter, url(#{$url}), webkit moz);
}
@mixin filter-invert($value: 100%) {
    @include prefixer(filter, invert($value), webkit moz);
}

@mixin filter-opacity($value) {
    @include prefixer(filter, opacity($value), webkit moz);
}

@mixin filter-saturate($value) {
    @include prefixer(filter, saturate($value), webkit moz);
}

//columns
//-----------------------------------------------------
@mixin columns($arg) {
    // <column-count> || <column-width> || <column-count> && <column-width>
    @include prefixer(columns, $arg, webkit moz);
}

@mixin column-count($int: auto) {
    // auto | integer
    @include prefixer(column-count, $int, webkit moz);
}

@mixin column-width($length: auto) {
    // auto | length
    @include prefixer(column-width, $length, webkit moz);
}

@mixin column-gap($length: normal) {
    // normal | length
    @include prefixer(column-gap, $length, webkit moz);
}

@mixin column-fill($arg: balance) {
    // auto | balance
    @include prefixer(columns-fill, $arg, webkit moz);
}

@mixin column-rule($arg: 1px solid $gray) {
    // <border-width> || <border-style> || <color>
    @include prefixer(column-rule, $arg, webkit moz);
}
@mixin column-rule-color($color) {
    @include prefixer(column-rule-color, $color, webkit moz);
}

@mixin column-rule-style($style: none) {
    // none | hidden | dashed | dotted | double | groove | inset | inset | outset | ridge | solid
    @include prefixer(column-rule-style, $style, webkit moz);
}

@mixin column-rule-width($width: none) {
    @include prefixer(column-rule-width, $width, webkit moz);
}
@mixin column-span($arg: none) {
    // none || all
    @include prefixer(column-span, $arg, webkit moz);
}

// webkit
//-----------------------------------------------------
// text
@mixin text-fill-color($color) {
    @include prefixer(text-fill-color, $color, webkit);
}

@mixin text-stroke-color($color) {
    @include prefixer(text-stroke-color, $color, webkit);
}

@mixin text-stroke-width($width) {
    @include prefixer(text-stroke-width, $width, webkit);
}

@mixin margin-collapse($value: collapse) {
    // collapse | separate
    @include prefixer(margin-collapse, $value, webkit);
}

// https://developer.apple.com/library/safari/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html#//apple_ref/doc/uid/TP30001266-SW16
@mixin box-reflect($reflect: below 1px) {
    // direction  offset  mask-box-image;
    @include prefixer(box-reflect, $reflect, webkit);
}

@mixin dpr($font-size) {
    font-size: $font-size;
    @if $retina {
        [data-dpr="2"] & {
            font-size: $font-size * 2;
        }
        [data-dpr="3"] & {
            font-size: $font-size * 3;
        }
    }
}

@mixin font-size($px) {
    @if $fontRem {
        font-size: rem($px);
    }
    @else {
        @include dpr(max(12, round(strip-units($px)/2))*1px);
    }
}


@mixin font-dpr($font-size) {
    font-size: $font-size;
    @if $retina {
        [data-dpr="2"] & {
            font-size: $font-size * 2;
        }
        [data-dpr="3"] & {
            font-size: $font-size * 3;
        }
    }
}

//简写
@mixin full_screen() {
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
}

@mixin radius_rem($v: 0) {
    @if $v==0 {
        @include prefixer(border-radius, 50%);
    }
    @else {
        @include prefixer(border-radius, rem($v));
    }
}

@mixin radius($v:0) {
    @if $v==0 {
        @include prefixer(border-radius, 50%);
    }
    @else {
        @include prefixer(border-radius, $v);
        @if $retina {
            [data-dpr="2"] & {
                @include prefixer(border-radius, new_value($v, 2));
            }
            [data-dpr="3"] & {
                @include prefixer(border-radius, new_value($v, 3));
            }
        }
    }
}
@mixin top_center() {
    position: absolute;
    top: 50%;
    @include translateY(-50%);
}

@mixin left_center() {
    position: absolute;
    left: 50%;
    @include translateX(-50%);
}

@mixin center() {
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
}

@mixin fl() {
    float: left;
}

@mixin fr() {
    float: right;
}

@mixin border_dir_scale($px, $color, $direction, $sc: 0.5){
    position: relative;
    border: 0 none;
    &:before{
        content: " ";
        position: absolute;
        //@if $direction == all {
        //    left: 0;
        //    top: 0;
        //    width: 200%;
        //    height: 200%;
        //    border: $px solid $color;
        //    @include transform-origin(0 0);
        //    @include scale($sc, $sc);
        //}
        //@else {
        //
        //}
        @each $v in $direction {
            border-#{$v}: $px solid $color;
            @if $v == top {
                left: 0;
                top: 0;
                width: 100%;
                height: 1px;
                @include scaleY($sc);
            }@else if $v == left {
                left: 0;
                top: 0;
                width: 1px;
                height: 100%;
                @include scaleX($sc);
            }@else if $v == right {
                right: 0;
                top: 0;
                width: 1px;
                height: 100%;
                @include scaleX($sc);
            }@else if $v == bottom {
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                @include scaleY($sc);
            }
        }
    }
}

@mixin dashed($px, $color, $direction:all) {
    @if $direction=="all" {
        border: $px dashed $color;
        @if $retina {
            .data-dir-2 & {
                border: $px * 2 dashed $color;
            }
            .data-dir-3 & {
                border: $px * 3 dashed $color;
            }
        }
    }
    @else {
        @each $v in $direction {
            border-#{$v}: $px dashed $color;
        }
        @if $retina {
            [data-dpr="2"] & {
                @each $v in $direction {
                    border-#{$v}: $px * 2 dashed $color;
                }
            }
            [data-dpr="3"] & {
                @each $v in $direction {
                    border-#{$v}: $px * 3 dashed $color;
                }
            }
        }
    }
}

@mixin solid($px, $color, $direction: all, $re:true) {
    @if $direction == all {
        border: $px solid $color;
        @if $borderLineRetina and $re {
            .iphone-data-dir-2 & {
                border: $px / 2 solid $color;
            }
            .iphone-data-dir-3 & {
                border: $px / 3 solid $color;
            }
        }
    }
    @else {
        @each $v in $direction {
            border-#{$v}: $px solid $color;
        }
        @if $borderLineRetina and $re {
            @if type_of($direction) == "list" {
                .iphone-data-dir-2 & {
                    @each $v in $direction {
                        border-#{$v}: $px / 2 solid $color;
                    }
                }
                .iphone-data-dir-3 & {
                    @each $v in $direction {
                        border-#{$v}: $px / 3 solid $color;
                    }
                }
            }@else{
                .iphone-data-dir-2 & ,.android-data-dir-2 & ,.other-data-dir-2 & {
                    @each $v in $direction {
                        @include border_dir_scale($px, $color, $v, 0.5);
                    }
                }
                .iphone-data-dir-3 & ,.android-data-dir-3 & ,.other-data-dir-3 & {
                    @each $v in $direction {
                        @include border_dir_scale($px, $color, $v, 0.333);
                    }
                }
            }
        }
    }
}

@mixin solid_rem($px, $color, $direction:all) {
    @if $direction==all {
        border: rem($px) solid $color;
    }
    @else {
        @each $v in $direction {
            border-#{$v}: rem($px) solid $color;
        }
    }
}

@mixin tar() {
    text-align: right;
}

@mixin tal() {
    text-align: left;
}

@mixin tac() {
    text-align: center;
}

@mixin lt($h) {
    line-height: rem($h);
}

@mixin lh($h) {
    height: rem($h);
    min-height: rem($h);
    max-height: rem($h);
    @include lt($h);
}

%scrolling {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

%flex-center{//水平垂直居中
    @include display-flex;
    @include justify-content(center);
    @include align-items(center);
}

%flex-left{//垂直居中-靠左
    @include display-flex;
    @include align-items(center);
}

%flex-right{//垂直居中-靠右
    @include display-flex;
    @include justify-content(flex-end);
    @include align-items(center);
}

%flex-between{//垂直居中-靠两边
    @include display-flex;
    @include justify-content;
    @include align-items(center);
}